export const prescriptionData = [
    {
        DMEItem: false,
        consentDatetime: '05/12/2021',
        consentStatus: 'A',
        consentStatusDesc: 'CONSENT ACCEPTED',
        consentUser: 'USER,AUTOMATION',
        dawCode: '1',
        dawDesc: 'Physician DAW(1)',
        directions: 'ONCE A DAY',
        dispensedDrugMaker: 'CIPLA USA',
        dispensedProductAlias: null,
        dispensedProductCompound: 'N',
        dispensedProductDeaClass: '0',
        dispensedProductDeaClassDesc: 'Not Controlled(0)',
        dispensedProductDosageForm: 'TABLET',
        dispensedProductGeneric: true,
        dispensedProductId: '32195',
        dispensedProductMultiSourceCode: 'Y',
        dispensedProductName: 'GEMFIBROZIL  TAB 600MG',
        dispensedProductNumber: '69097082112',
        dispensedProductStrength: '600',
        dispensedProductType: 'G',
        dispensedProductUnitOfMeasure: 'MG',
        doctorFax: '(555) 555-5555',
        doctorName: 'AABERG,THOMAS',
        doctorPhone: '(555) 555-5555',
        epostDoctorNum: '10371',
        epostMemberId: '462166',
        epostOrderNum: '6762198',
        epostPatientNum: '323795',
        familyId: '1248292',
        fillDaysSupply: '140',
        fillNumber: '1',
        fillQuantity: '90',
        fillsRemaining: '19',
        genderCode: 'F',
        genderDesc: 'FEMALE',
        genericProductIndicator: '39200030000310',
        itemInWorkflow: false,
        itemQueueStatus: null,
        languageDesc: 'ENGLISH',
        languageId: '2',
        lastFillDate: '05/12/2021',
        nextFillDate: '07/25/2021',
        numRefills: '18',
        orderInvoiceNumber: '7800374',
        orderLineQueueStatus: 'SHIPPED',
        orderLineStatus: '3',
        orderQueueStatus: 'SHIPPED',
        orderStatus: 'SHIPPED',
        patientCopay: '$0.00',
        patientDOB: '07/07/2003',
        patientEmail: 'tested@medimpactdirect.com',
        patientFirstName: 'TestFifty',
        patientLastName: 'Patient',
        patientMiddleName: null,
        payorPaidAmount: '$0.00',
        personCode: '01 ',
        previousRxNumber: null,
        quantityRemaining: '1710',
        relationshipCode: '1',
        relationshipDesc: 'CARDHOLDER(1)',
        rxExpirationDate: '05/12/2022',
        rxFillNumber: '68017395/1',
        rxFillable: true,
        rxNumber: '68017395',
        rxPrecheckDatetime: null,
        rxPrecheckInitials: null,
        rxSeqNum: '8773134',
        rxStatus: 'Open',
        rxStatusNum: '7',
        rxTxn: 'NEWRX',
        rxType: 'MAILORDER',
        safetyCaps: 'Y',
        specialityItem: false,
        writtenDate: '05/12/2021',
        writtenDrugMaker: 'CIPLA USA',
        writtenProductAlias: null,
        writtenProductCompound: 'N',
        writtenProductDeaClass: '0',
        writtenProductDeaClassDesc: 'Not Controlled(0)',
        writtenProductDosageForm: 'TABLET',
        writtenProductGeneric: true,
        writtenProductId: '32195',
        writtenProductMultiSourceCode: 'Y',
        writtenProductName: 'GEMFIBROZIL  TAB 600MG',
        writtenProductNumber: '69097082112',
        writtenProductStrength: '600',
        writtenProductType: 'G',
        writtenProductUnitOfMeasure: 'MG',
        writtenQuantity: '90',
        rphChecked: true,
        webEligibilityStatus: 'ELIGIBLE',
        inOrderCart: false,
        prevScriptId: null,
        autoFillStatus: 'N',
        messageErrorText: null,
        messageStatus: null,
        messageText: null
    },
    {
        DMEItem: false,
        consentDatetime: '07/20/2021',
        consentStatus: 'P',
        consentStatusDesc: 'CONSENT PENDING CONTACT',
        consentUser: 'SYSTEM,SYSTEM',
        dawCode: '0',
        dawDesc: 'No DAW(0)',
        directions: 'ONCE A DAY',
        dispensedDrugMaker: 'APOTEX',
        dispensedProductAlias: null,
        dispensedProductCompound: 'N',
        dispensedProductDeaClass: '0',
        dispensedProductDeaClassDesc: 'Not Controlled(0)',
        dispensedProductDosageForm: 'TABLET',
        dispensedProductGeneric: true,
        dispensedProductId: '924',
        dispensedProductMultiSourceCode: 'Y',
        dispensedProductName: 'ATORVASTATIN TAB 10MG',
        dispensedProductNumber: '60505257808',
        dispensedProductStrength: '10',
        dispensedProductType: 'G',
        dispensedProductUnitOfMeasure: 'MG',
        doctorFax: '(555) 555-5555',
        doctorName: 'TEST,A',
        doctorPhone: '(555) 555-5555',
        epostDoctorNum: '92555',
        epostMemberId: '462074',
        epostOrderNum: '6763513',
        epostPatientNum: '323703',
        familyId: '1248200',
        fillDaysSupply: '90',
        fillNumber: '1',
        fillQuantity: '90',
        fillsRemaining: '15',
        genderCode: 'F',
        genderDesc: 'FEMALE',
        genericProductIndicator: '39400010100310',
        itemInWorkflow: true,
        itemQueueStatus: 'VALIDATE RX SANITY CHECK',
        languageDesc: 'ENGLISH',
        languageId: '2',
        lastFillDate: null,
        nextFillDate: '03/25/2021',
        numRefills: '14',
        orderInvoiceNumber: '7801602',
        orderLineQueueStatus: 'SANITY CHECK ERRORS',
        orderLineStatus: '1',
        orderQueueStatus: 'OPEN',
        orderStatus: 'OPEN',
        patientCopay: '$0.00',
        patientDOB: '07/02/1950',
        patientEmail: 'tested@medimpactdirect.com',
        patientFirstName: 'TestThree',
        patientLastName: 'Patient',
        patientMiddleName: null,
        payorPaidAmount: '$0.00',
        personCode: '01 ',
        previousRxNumber: null,
        quantityRemaining: '1350',
        relationshipCode: '1',
        relationshipDesc: 'CARDHOLDER(1)',
        rxExpirationDate: '03/25/2022',
        rxFillNumber: '68016521/1',
        rxFillable: true,
        rxNumber: '68016521',
        rxPrecheckDatetime: null,
        rxPrecheckInitials: null,
        rxSeqNum: '8775034',
        rxStatus: 'Open',
        rxStatusNum: '7',
        rxTxn: 'NEWRX',
        rxType: 'MAILORDER',
        safetyCaps: 'Y',
        specialityItem: false,
        writtenDate: '03/25/2021',
        writtenDrugMaker: 'PFIZER U.S.',
        writtenProductAlias: null,
        writtenProductCompound: 'N',
        writtenProductDeaClass: '0',
        writtenProductDeaClassDesc: 'Not Controlled(0)',
        writtenProductDosageForm: 'TABLET',
        writtenProductGeneric: false,
        writtenProductId: '2752',
        writtenProductMultiSourceCode: 'O',
        writtenProductName: 'LIPITOR TAB 10MG',
        writtenProductNumber: '00071015523',
        writtenProductStrength: '10',
        writtenProductType: 'T',
        writtenProductUnitOfMeasure: 'MG',
        writtenQuantity: '90',
        rphChecked: true,
        webEligibilityStatus: 'NOT_ELIGIBLE',
        inOrderCart: false,
        prevScriptId: null,
        autoFillStatus: 'N',
        messageErrorText: null,
        messageStatus: null,
        messageText: null
    },

    {
        DMEItem: false,
        consentDatetime: '04/19/2021',
        consentStatus: 'A',
        consentStatusDesc: 'CONSENT ACCEPTED',
        consentUser: 'VADIVELU,KARTHIKEYAN',
        dawCode: '1',
        dawDesc: 'Physician DAW(1)',
        directions: '1 TABLET',
        dispensedDrugMaker: 'AUROBINDO PHARMA',
        dispensedProductAlias: null,
        dispensedProductCompound: 'N',
        dispensedProductDeaClass: '0',
        dispensedProductDeaClassDesc: 'Not Controlled(0)',
        dispensedProductDosageForm: 'TABLET',
        dispensedProductGeneric: true,
        dispensedProductId: '39125',
        dispensedProductMultiSourceCode: 'Y',
        dispensedProductName: 'METHENAM HIP TAB 1GM',
        dispensedProductNumber: '65862078201',
        dispensedProductStrength: '1',
        dispensedProductType: 'G',
        dispensedProductUnitOfMeasure: 'GM',
        doctorFax: '(555) 555-5555',
        doctorName: 'SAAB,AHMAD',
        doctorPhone: '(555) 555-5555',
        epostDoctorNum: '56562',
        epostMemberId: '462073',
        epostOrderNum: '6761306',
        epostPatientNum: '323702',
        familyId: '1248199',
        fillDaysSupply: '90',
        fillNumber: '1',
        fillQuantity: '90',
        fillsRemaining: '0',
        genderCode: 'M',
        genderDesc: 'MALE',
        genericProductIndicator: '53000020200305',
        itemInWorkflow: false,
        itemQueueStatus: null,
        languageDesc: 'ENGLISH',
        languageId: '2',
        lastFillDate: '04/19/2021',
        nextFillDate: '07/02/2021',
        numRefills: '0',
        orderInvoiceNumber: '7799578',
        orderLineQueueStatus: 'SHIPPED',
        orderLineStatus: '3',
        orderQueueStatus: 'SHIPPED',
        orderStatus: 'SHIPPED',
        patientCopay: '$0.00',
        patientDOB: '12/04/1963',
        patientEmail: 'tested@medimpactdirect.com',
        patientFirstName: 'TestTwo',
        patientLastName: 'Patient',
        patientMiddleName: null,
        payorPaidAmount: '$0.00',
        personCode: '01 ',
        previousRxNumber: null,
        quantityRemaining: '0',
        relationshipCode: '1',
        relationshipDesc: 'CARDHOLDER(1)',
        rxExpirationDate: '04/19/2022',
        rxFillNumber: '68017041/1',
        rxFillable: false,
        rxNumber: '68017041',
        rxPrecheckDatetime: null,
        rxPrecheckInitials: null,
        rxSeqNum: '8772030',
        rxStatus: 'Open',
        rxStatusNum: '7',
        rxTxn: 'NEWRX',
        rxType: 'MAILORDER',
        safetyCaps: 'Y',
        specialityItem: false,
        writtenDate: '04/19/2021',
        writtenDrugMaker: 'AUROBINDO PHARMA',
        writtenProductAlias: null,
        writtenProductCompound: 'N',
        writtenProductDeaClass: '0',
        writtenProductDeaClassDesc: 'Not Controlled(0)',
        writtenProductDosageForm: 'TABLET',
        writtenProductGeneric: true,
        writtenProductId: '39125',
        writtenProductMultiSourceCode: 'Y',
        writtenProductName: 'METHENAM HIP TAB 1GM',
        writtenProductNumber: '65862078201',
        writtenProductStrength: '1',
        writtenProductType: 'G',
        writtenProductUnitOfMeasure: 'GM',
        writtenQuantity: '90',
        rphChecked: true,
        webEligibilityStatus: 'AUTH_REQ',
        inOrderCart: false,
        prevScriptId: null,
        autoFillStatus: 'N',
        messageErrorText: null,
        messageStatus: null,
        messageText: null
    },
    {
        DMEItem: false,
        consentDatetime: '03/23/2021',
        consentStatus: 'P',
        consentStatusDesc: 'CONSENT PENDING CONTACT',
        consentUser: 'GLIDE,ROB',
        dawCode: '0',
        dawDesc: 'No DAW(0)',
        directions: 'ONCE A DAY',
        dispensedDrugMaker: 'ACCORD HEALTHCARE',
        dispensedProductAlias: null,
        dispensedProductCompound: 'N',
        dispensedProductDeaClass: '0',
        dispensedProductDeaClassDesc: 'Not Controlled(0)',
        dispensedProductDosageForm: 'TABLET',
        dispensedProductGeneric: true,
        dispensedProductId: '18561',
        dispensedProductMultiSourceCode: 'Y',
        dispensedProductName: 'SIMVASTATIN TAB 20MG',
        dispensedProductNumber: '16729000517',
        dispensedProductStrength: '20',
        dispensedProductType: 'G',
        dispensedProductUnitOfMeasure: 'MG',
        doctorFax: '(555) 555-5555',
        doctorName: 'TEST,A',
        doctorPhone: '(555) 555-5555',
        epostDoctorNum: '92555',
        epostMemberId: '462068',
        epostOrderNum: '6762428',
        epostPatientNum: '323697',
        familyId: '1248194',
        fillDaysSupply: '30',
        fillNumber: '1',
        fillQuantity: '30',
        fillsRemaining: '6',
        genderCode: 'M',
        genderDesc: 'MALE',
        genericProductIndicator: '39400075000330',
        itemInWorkflow: false,
        itemQueueStatus: null,
        languageDesc: 'ENGLISH',
        languageId: '2',
        lastFillDate: null,
        nextFillDate: '03/23/2021',
        numRefills: '5',
        orderInvoiceNumber: '7800604',
        orderLineQueueStatus: 'NOTFOUND',
        orderLineStatus: '1',
        orderQueueStatus: 'OPEN',
        orderStatus: 'OPEN',
        patientCopay: '$0.00',
        patientDOB: '04/15/1958',
        patientEmail: 'tested@medimpactdirect.com',
        patientFirstName: 'TestOne',
        patientLastName: 'Patient',
        patientMiddleName: null,
        payorPaidAmount: '$0.00',
        personCode: '01 ',
        previousRxNumber: null,
        quantityRemaining: '180',
        relationshipCode: '1',
        relationshipDesc: 'CARDHOLDER(1)',
        rxExpirationDate: '03/23/2022',
        rxFillNumber: '68016480/1',
        rxFillable: true,
        rxNumber: '68016480',
        rxPrecheckDatetime: null,
        rxPrecheckInitials: null,
        rxSeqNum: '8773554',
        rxStatus: 'Open',
        rxStatusNum: '7',
        rxTxn: 'NEWRX',
        rxType: 'MAILORDER',
        safetyCaps: 'Y',
        specialityItem: false,
        writtenDate: '03/23/2021',
        writtenDrugMaker: 'MERCK SHARP & DOHME',
        writtenProductAlias: null,
        writtenProductCompound: 'N',
        writtenProductDeaClass: '0',
        writtenProductDeaClassDesc: 'Not Controlled(0)',
        writtenProductDosageForm: 'TABLET',
        writtenProductGeneric: false,
        writtenProductId: '4727',
        writtenProductMultiSourceCode: 'O',
        writtenProductName: 'ZOCOR TAB 20MG',
        writtenProductNumber: '00006074031',
        writtenProductStrength: '20',
        writtenProductType: 'T',
        writtenProductUnitOfMeasure: 'MG',
        writtenQuantity: '30',
        rphChecked: true,
        webEligibilityStatus: 'NOT_ELIGIBLE',
        inOrderCart: true,
        prevScriptId: null,
        autoFillStatus: 'N',
        messageErrorText: null,
        messageStatus: null,
        messageText: null
    },
    {
        DMEItem: false,
        consentDatetime: '03/24/2021',
        consentStatus: 'P',
        consentStatusDesc: 'CONSENT PENDING CONTACT',
        consentUser: 'GLIDE,ROB',
        dawCode: '0',
        dawDesc: 'No DAW(0)',
        directions: 'ONCE A DAY',
        dispensedDrugMaker: 'TORRENT PHARMACEUTICALS',
        dispensedProductAlias: null,
        dispensedProductCompound: 'N',
        dispensedProductDeaClass: '0',
        dispensedProductDeaClassDesc: 'Not Controlled(0)',
        dispensedProductDosageForm: 'TABLET',
        dispensedProductGeneric: true,
        dispensedProductId: '42601',
        dispensedProductMultiSourceCode: 'Y',
        dispensedProductName: 'SILDENAFIL   TAB 25MG',
        dispensedProductNumber: '13668018630',
        dispensedProductStrength: '25',
        dispensedProductType: 'G',
        dispensedProductUnitOfMeasure: 'MG',
        doctorFax: '(555) 555-5555',
        doctorName: 'TEST,A',
        doctorPhone: '(555) 555-5555',
        epostDoctorNum: '92555',
        epostMemberId: '462068',
        epostOrderNum: '6760494',
        epostPatientNum: '323697',
        familyId: '1248194',
        fillDaysSupply: '1',
        fillNumber: '1',
        fillQuantity: '1',
        fillsRemaining: '5',
        genderCode: 'M',
        genderDesc: 'MALE',
        genericProductIndicator: '40304070100310',
        itemInWorkflow: false,
        itemQueueStatus: null,
        languageDesc: 'ENGLISH',
        languageId: '2',
        lastFillDate: '03/24/2021',
        nextFillDate: '03/24/2021',
        numRefills: '5',
        orderInvoiceNumber: '7798764',
        orderLineQueueStatus: 'SHIPPED',
        orderLineStatus: '3',
        orderQueueStatus: 'SHIP READY',
        orderStatus: 'SHIP READY',
        patientCopay: '$0.00',
        patientDOB: '04/15/1958',
        patientEmail: 'tested@medimpactdirect.com',
        patientFirstName: 'TestOne',
        patientLastName: 'Patient',
        patientMiddleName: null,
        payorPaidAmount: '$0.00',
        personCode: '01 ',
        previousRxNumber: null,
        quantityRemaining: '5',
        relationshipCode: '1',
        relationshipDesc: 'CARDHOLDER(1)',
        rxExpirationDate: '03/24/2022',
        rxFillNumber: '68016508/1',
        rxFillable: true,
        rxNumber: '68016508',
        rxPrecheckDatetime: null,
        rxPrecheckInitials: null,
        rxSeqNum: '8771201',
        rxStatus: 'Open',
        rxStatusNum: '7',
        rxTxn: 'NEWRX',
        rxType: 'MAILORDER',
        safetyCaps: 'Y',
        specialityItem: false,
        writtenDate: '03/24/2021',
        writtenDrugMaker: 'PFIZER U.S.',
        writtenProductAlias: null,
        writtenProductCompound: 'N',
        writtenProductDeaClass: '0',
        writtenProductDeaClassDesc: 'Not Controlled(0)',
        writtenProductDosageForm: 'TABLET',
        writtenProductGeneric: false,
        writtenProductId: '2743',
        writtenProductMultiSourceCode: 'O',
        writtenProductName: 'VIAGRA TAB 25MG',
        writtenProductNumber: '00069420030',
        writtenProductStrength: '25',
        writtenProductType: 'T',
        writtenProductUnitOfMeasure: 'MG',
        writtenQuantity: '1',
        rphChecked: true,
        webEligibilityStatus: 'NOT_ELIGIBLE',
        inOrderCart: false,
        prevScriptId: null,
        autoFillStatus: 'N',
        messageErrorText: null,
        messageStatus: null,
        messageText: null
    },
    {
        DMEItem: false,
        consentDatetime: '04/02/2021',
        consentStatus: 'P',
        consentStatusDesc: 'CONSENT PENDING CONTACT',
        consentUser: 'BAILEY,KEVIN',
        dawCode: '0',
        dawDesc: 'No DAW(0)',
        directions: 'ONCE A DAY',
        dispensedDrugMaker: 'LUPIN PHARMACEUTICALS',
        dispensedProductAlias: null,
        dispensedProductCompound: 'N',
        dispensedProductDeaClass: '0',
        dispensedProductDeaClassDesc: 'Not Controlled(0)',
        dispensedProductDosageForm: 'TABLET',
        dispensedProductGeneric: true,
        dispensedProductId: '29888',
        dispensedProductMultiSourceCode: 'Y',
        dispensedProductName: 'LOSARTAN/HCT TAB 100-25',
        dispensedProductNumber: '68180021709',
        dispensedProductStrength: '100-25',
        dispensedProductType: 'G',
        dispensedProductUnitOfMeasure: 'MG',
        doctorFax: '(555) 555-5555',
        doctorName: 'TEST,A',
        doctorPhone: '(555) 555-5555',
        epostDoctorNum: '92555',
        epostMemberId: '462068',
        epostOrderNum: '6760730',
        epostPatientNum: '323697',
        familyId: '1248194',
        fillDaysSupply: '90',
        fillNumber: '1',
        fillQuantity: '90',
        fillsRemaining: '10',
        genderCode: 'M',
        genderDesc: 'MALE',
        genericProductIndicator: '36994002450340',
        itemInWorkflow: false,
        itemQueueStatus: null,
        languageDesc: 'ENGLISH',
        languageId: '2',
        lastFillDate: '04/02/2021',
        nextFillDate: '06/15/2021',
        numRefills: '10',
        orderInvoiceNumber: '7799002',
        orderLineQueueStatus: 'SHIPPED',
        orderLineStatus: '3',
        orderQueueStatus: 'SHIP READY',
        orderStatus: 'SHIP READY',
        patientCopay: '$0.00',
        patientDOB: '04/15/1958',
        patientEmail: 'tested@medimpactdirect.com',
        patientFirstName: 'TestOne',
        patientLastName: 'Patient',
        patientMiddleName: null,
        payorPaidAmount: '$0.00',
        personCode: '01 ',
        previousRxNumber: null,
        quantityRemaining: '900',
        relationshipCode: '1',
        relationshipDesc: 'CARDHOLDER(1)',
        rxExpirationDate: '04/02/2022',
        rxFillNumber: '68016625/1',
        rxFillable: true,
        rxNumber: '68016625',
        rxPrecheckDatetime: null,
        rxPrecheckInitials: null,
        rxSeqNum: '8771413',
        rxStatus: 'Open',
        rxStatusNum: '7',
        rxTxn: 'NEWRX',
        rxType: 'MAILORDER',
        safetyCaps: 'Y',
        specialityItem: false,
        writtenDate: '04/02/2021',
        writtenDrugMaker: 'MERCK SHARP & DOHME',
        writtenProductAlias: null,
        writtenProductCompound: 'N',
        writtenProductDeaClass: '0',
        writtenProductDeaClassDesc: 'Not Controlled(0)',
        writtenProductDosageForm: 'TABLET',
        writtenProductGeneric: false,
        writtenProductId: '4730',
        writtenProductMultiSourceCode: 'O',
        writtenProductName: 'HYZAAR TAB 100-25',
        writtenProductNumber: '00006074731',
        writtenProductStrength: '100-25',
        writtenProductType: 'T',
        writtenProductUnitOfMeasure: 'MG',
        writtenQuantity: '90',
        rphChecked: true,
        webEligibilityStatus: 'NOT_ELIGIBLE',
        inOrderCart: false,
        prevScriptId: null,
        autoFillStatus: 'N',
        messageErrorText: null,
        messageStatus: null,
        messageText: null
    },
    {
        DMEItem: false,
        consentDatetime: '04/02/2021',
        consentStatus: 'P',
        consentStatusDesc: 'CONSENT PENDING CONTACT',
        consentUser: 'BAILEY,KEVIN',
        dawCode: '0',
        dawDesc: 'No DAW(0)',
        directions: 'ONCE A DAY',
        dispensedDrugMaker: 'ACI HEALTHCARE USA',
        dispensedProductAlias: null,
        dispensedProductCompound: 'N',
        dispensedProductDeaClass: '0',
        dispensedProductDeaClassDesc: 'Not Controlled(0)',
        dispensedProductDosageForm: 'TABLET',
        dispensedProductGeneric: true,
        dispensedProductId: '43617',
        dispensedProductMultiSourceCode: 'Y',
        dispensedProductName: 'METFORMIN    TAB 500MG',
        dispensedProductNumber: '71093013206',
        dispensedProductStrength: '500',
        dispensedProductType: 'G',
        dispensedProductUnitOfMeasure: 'MG',
        doctorFax: '(555) 555-5555',
        doctorName: 'TEST,A',
        doctorPhone: '(555) 555-5555',
        epostDoctorNum: '92555',
        epostMemberId: '462068',
        epostOrderNum: '6760913',
        epostPatientNum: '323697',
        familyId: '1248194',
        fillDaysSupply: '10',
        fillNumber: '1',
        fillQuantity: '10',
        fillsRemaining: '2',
        genderCode: 'M',
        genderDesc: 'MALE',
        genericProductIndicator: '27250050000320',
        itemInWorkflow: false,
        itemQueueStatus: null,
        languageDesc: 'ENGLISH',
        languageId: '2',
        lastFillDate: null,
        nextFillDate: '04/02/2021',
        numRefills: '1',
        orderInvoiceNumber: '7799185',
        orderLineQueueStatus: 'NOTFOUND',
        orderLineStatus: '1',
        orderQueueStatus: 'CLOSED',
        orderStatus: 'CLOSED',
        patientCopay: '$0.00',
        patientDOB: '04/15/1958',
        patientEmail: 'tested@medimpactdirect.com',
        patientFirstName: 'TestOne',
        patientLastName: 'Patient',
        patientMiddleName: null,
        payorPaidAmount: '$0.00',
        personCode: '01 ',
        previousRxNumber: null,
        quantityRemaining: '20',
        relationshipCode: '1',
        relationshipDesc: 'CARDHOLDER(1)',
        rxExpirationDate: '04/02/2022',
        rxFillNumber: '68016626/1',
        rxFillable: true,
        rxNumber: '68016626',
        rxPrecheckDatetime: null,
        rxPrecheckInitials: null,
        rxSeqNum: '8771628',
        rxStatus: 'Open',
        rxStatusNum: '7',
        rxTxn: 'NEWRX',
        rxType: 'MAILORDER',
        safetyCaps: 'Y',
        specialityItem: false,
        writtenDate: '04/02/2021',
        writtenDrugMaker: 'B-M SQUIBB U.S. (PRIMARY CARE)',
        writtenProductAlias: null,
        writtenProductCompound: 'N',
        writtenProductDeaClass: '0',
        writtenProductDeaClassDesc: 'Not Controlled(0)',
        writtenProductDosageForm: 'TABLET',
        writtenProductGeneric: false,
        writtenProductId: '5542',
        writtenProductMultiSourceCode: 'O',
        writtenProductName: 'GLUCOPHAGE TAB 500MG',
        writtenProductNumber: '00087606005',
        writtenProductStrength: '500',
        writtenProductType: 'T',
        writtenProductUnitOfMeasure: 'MG',
        writtenQuantity: '10',
        rphChecked: true,
        webEligibilityStatus: 'NOT_ELIGIBLE',
        inOrderCart: false,
        prevScriptId: null,
        autoFillStatus: 'N',
        messageErrorText: null,
        messageStatus: null,
        messageText: null
    }
];
