import { TFunction, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { PerscriptionObjectPayload } from 'state/medicine-cabinet/medicine-cabinet.services';
import Button from 'ui-kit/button/button';
import PrescriptionCardIcon from './PrescriptionCard.icon';

import './PrescriptionCard.style.scss';

export interface PrescriptionCardProps {
    fullPayload: object;
    prescriptionName: string;
    rxNumber: string;
    orderStatus: 'NEW_RX' | 'ORDERED' | 'SHIPPED' | 'REFILL_TOO_SOON' | 'REFILL_AVAILABLE' | 'IN_CART' | 'NO_REFILLS';
    inOrderCart: boolean;
    refillsLeft: number;
    sortOrder: number;
    details?: {
        detail: React.ReactNode | string;
        primary?: boolean;
    }[];
    statuses?: {
        status: React.ReactNode | Element | string;
        primary?: boolean;
    }[];
    ctas?: {
        label: string;
        onClick: () => void;
        disabled?: boolean;
        isMobile?: boolean;
    }[];
    isAddingToCart: boolean;
}

function addDays(date: string, days: number): Date {
    const origDate = new Date(date);
    origDate.setDate(origDate.getDate() + days);
    return origDate;
}

function isShipStatusExpired(shipDate: string): Boolean {
    const showShippedStatusDays = 14;
    const shippedExpirationDate = addDays(shipDate, showShippedStatusDays);
    const today = new Date(Date.now());
    return !!(shippedExpirationDate < today);
}

export function prescriptionPayloadToProps(
    payload: PerscriptionObjectPayload,
    t: TFunction<string>,
    onOrderRxClick?: () => void
): PrescriptionCardProps {
    onOrderRxClick = onOrderRxClick ? onOrderRxClick : () => {};
    let orderStatus: PrescriptionCardProps['orderStatus'] = 'NO_REFILLS';
    let sortOrder = 6;
    if (payload.webEligibilityStatus === 'ELIGIBLE' && payload.rxStatus === 'Profiled') {
        orderStatus = 'NEW_RX';
        sortOrder = 2;
    }
    if (payload.webEligibilityStatus === 'NOT_ELIGIBLE' && payload.itemInWorkflow === true) {
        orderStatus = 'ORDERED';
        sortOrder = 4;
    }
    if (
        payload.webEligibilityStatus === 'NOT_ELIGIBLE' &&
        payload.orderLineQueueStatus === 'SHIPPED' &&
        payload.itemInWorkflow === false
    ) {
        orderStatus = isShipStatusExpired(payload.lastFillDate) ? 'REFILL_TOO_SOON' : 'SHIPPED';
        sortOrder = isShipStatusExpired(payload.lastFillDate) ? 7 : 5;
    }
    if (
        payload.webEligibilityStatus === 'NOT_ELIGIBLE' &&
        payload.nextFillDate !== undefined &&
        payload.itemInWorkflow === false
    ) {
        orderStatus = isShipStatusExpired(payload.lastFillDate) ? 'REFILL_TOO_SOON' : 'SHIPPED';
        sortOrder = isShipStatusExpired(payload.lastFillDate) ? 7 : 5;
    }
    if (payload.webEligibilityStatus === 'ELIGIBLE' && payload.rxStatus !== 'Profiled') {
        orderStatus = 'REFILL_AVAILABLE';
        sortOrder = 3;
    }
    if (payload.inOrderCart) {
        orderStatus = 'IN_CART';
        sortOrder = 1;
    }

    const props = {
        fullPayload: payload, // for debugging
        prescriptionName: payload.dispensedProductName,
        orderStatus: orderStatus,
        sortOrder: sortOrder,
        inOrderCart: payload.inOrderCart,
        refillsLeft: Number(payload.fillsRemaining),
        rxNumber: payload.rxNumber,
        details: [
            {
                detail: t('components.prescriptionCard.quantity', {
                    fillQuantity: payload.fillQuantity
                })
            },
            {
                detail:
                    payload.dispensedProductStrength +
                    (payload.dispensedProductUnitOfMeasure ? payload.dispensedProductUnitOfMeasure.toLowerCase() : ''),
                primary: true
            },
            {
                detail: payload.dispensedProductDosageForm ? payload.dispensedProductDosageForm.toLowerCase() : null
            }
        ],
        statuses: [] as any[],
        ctas: [
            {
                label:
                    orderStatus !== 'IN_CART'
                        ? t('components.prescriptionCard.orderPrescription')
                        : t('components.prescriptionCard.inYourCart'),
                onClick: onOrderRxClick,
                disabled:
                    orderStatus !== 'NEW_RX' && orderStatus !== 'REFILL_AVAILABLE' && orderStatus !== 'NO_REFILLS',
                isMobile: false
            }
        ]
    };
    if (orderStatus === 'IN_CART') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.inYourCart')}</div>
        });
    }
    if (orderStatus === 'NEW_RX') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderNewRxArrived')}</div>
        });
    }
    if (orderStatus === 'ORDERED') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderRxOrdered')}</div>
        });
        props.statuses.push({
            primary: false,
            status: (
                <div className="prescription-card__details-status-headings font-weight-bold">
                    {t('components.prescriptionCard.orderStatusText')}
                </div>
            )
        });
        props.statuses.push({
            primary: false,
            status: <div>{t('components.prescriptionCard.orderInProgress')}</div>
        });
    } else if (orderStatus === 'SHIPPED') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderRxOrdered')}</div>
        });
        props.statuses.push({
            primary: false,
            status: (
                <div className="prescription-card__details-status-headings font-weight-bold">
                    {t('components.prescriptionCard.orderStatusText')}
                </div>
            )
        });
        props.statuses.push({
            primary: false,
            status: <div>{t('components.prescriptionCard.orderShippedText')}</div>
        });
    } else if (orderStatus === 'REFILL_TOO_SOON') {
        props.statuses.push({
            primary: true,
            status: t('components.prescriptionCard.orderRefillTooSoon')
        });
        props.statuses.push({
            primary: false,
            status: (
                <>
                    <div>{t('components.prescriptionCard.orderNextRefillAva')}</div>
                    <div>{payload.nextFillDate}</div>
                </>
            )
        });
    } else if (orderStatus === 'REFILL_AVAILABLE') {
        props.statuses.push({
            primary: true,
            status: t('components.prescriptionCard.orderRefillAva')
        });
    } else if (orderStatus === 'NO_REFILLS') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderNoRefillsRemaining')}</div>
        });

        props.statuses.push({
            primary: false,
            status: <small>{t('components.prescriptionCard.orderPreAuthReq')}</small>
        });
    }

    return props;
}

function showDebuginfo(card: object) {
    console.group(`${card.dispensedProductName}`);
    console.log('Prescription Card: ' + card.dispensedProductName);
    console.log('webEligibilityStatus: ' + card.webEligibilityStatus);
    console.log('inOrderCart: ' + card.inOrderCart);
    console.log('rxStatus: ' + card.rxStatus);
    console.log('itemInWorkflow: ' + card.itemInWorkflow);
    console.log('orderLineQueueStatus: ' + card.orderLineQueueStatus);
    console.log('nextFillDate: ' + card.nextFillDate);
    console.log('lastFillDate: ' + card.lastFillDate);
    console.log('shipStatusExpirationDate: ' + addDays(card.lastFillDate, 14).toLocaleDateString('en-US'));
    console.log('isShipStatusExpired: ' + isShipStatusExpired(card.lastFillDate));
    console.log(card);
    console.groupEnd();
}

export default function PrescriptionCard({
    fullPayload,
    prescriptionName,
    orderStatus,
    refillsLeft,
    rxNumber,
    details = [],
    statuses = [],
    ctas = [],
    isAddingToCart
}: PrescriptionCardProps) {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const [isRxBusy, setIsRxBusy] = useState(false);

    return (
        <Container
            fluid
            className={`prescription-card pb-3 h-100 ${expanded ? 'expanded' : ''} ${orderStatus
                .toLowerCase()
                .replace(/ /g, '_')}`}
        >
            <Row className={'h-100'}>
                <Col xs={12} className={'align-self-start'}>
                    <Row className={'prescription-card__heading justify-content-between'}>
                        <Col xs={12} className="prescription-card__title-container">
                            <h3 className="prescription-card__title">{prescriptionName}</h3>
                            <div className="prescription-card__subtitle">
                                {t('components.prescriptionCard.rxNumberHeaderText')}
                                {rxNumber}
                            </div>
                        </Col>
                        <div
                            className={'prescription-card__icon-badge mr-3'}
                            role="button"
                            tabIndex={0}
                            onKeyDown={() => {}}
                            onClick={() => {
                                showDebuginfo(fullPayload);
                            }}
                        >
                            <PrescriptionCardIcon variant={orderStatus} />
                        </div>
                    </Row>
                    <Row className="prescription-card__details-statuses py-3">
                        <Col className="prescription-card__details" xs={5}>
                            <div className="prescription-card__details-status-headings font-weight-bold">
                                {t('components.prescriptionCard.detailsTitle')}
                            </div>
                            <ul className="prescription-card__details-list">
                                {details.map(({ detail, primary }, index) => (
                                    <li
                                        key={index}
                                        className={`prescription-card__detail ${
                                            primary || expanded ? 'is-primary' : ''
                                        }`}
                                    >
                                        {detail}
                                    </li>
                                ))}
                            </ul>
                        </Col>
                        <Col className="prescription-card__status" xs={7}>
                            <div className="prescription-card__details-status-headings font-weight-bold">
                                {t('components.prescriptionCard.statusTitle')}
                            </div>
                            <ul className="prescription-card__status-list">
                                {statuses.map(({ status, primary }, index) => (
                                    <li
                                        key={index}
                                        className={`prescription-card__status ${
                                            primary || expanded ? 'is-primary' : ''
                                        }`}
                                    >
                                        {status}
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} className={'align-self-end'}>
                    <Row className={`my-3 font-weight-bold`}>
                        <Col className={`prescription-card__refills ${expanded ? 'is-primary' : ''}`} xs={12}>
                            {t('components.prescriptionCard.refillsLeftTitle', {
                                refillsLeft
                            })}
                        </Col>
                    </Row>
                    <Row className="d-flex align-items-center prescription-card__ctas">
                        <Col xs={5} sm={5} md={12} className="justify-content-start">
                            {ctas.map((cta, idx) => (
                                <Button
                                    key={`prescription-card-cta-button-${idx}`}
                                    {...cta}
                                    type={'button'}
                                    async
                                    onClick={() => {
                                        setIsRxBusy(true);
                                        cta.onClick();
                                    }}
                                    isBusy={isRxBusy && isAddingToCart}
                                    disabled={isAddingToCart || cta.disabled}
                                    dataGALocation="MedicineCabinet"
                                    variant={cta.disabled ? 'outline-smoke' : 'outline-primary'}
                                />
                            ))}
                        </Col>
                        <Col xs={7} sm={7} md={'auto'} className="d-md-none justify-content-end">
                            <Button
                                className="prescription-card__toggle-more-details"
                                label={!expanded ? '+ More Details' : '- Hide Details'}
                                type="button"
                                variant="text-blue"
                                onClick={() => {
                                    setExpanded(!expanded);
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}
