import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';

import { useAddTransferPrescription } from 'hooks/useAddTransferPrescription';

import { formatPrice } from 'schema/price.schema';

import { medicineCabinetGetAllPrescriptions } from 'state/medicine-cabinet/medicine-cabinet.routines';
import { startCartRoutine, updateRefillLinesRoutine } from 'state/cart/cart.routines';
import { cartItemsSelector } from 'state/cart/cart.selectors';
import {
    medicineCabinetPrescriptionsSelector,
    medicineShowNewPrescriptionModalSelector
} from 'state/medicine-cabinet/medicine-cabinet.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { closeNewPrescriptionModal } from 'state/medicine-cabinet/medicine-cabinet.reducers';
import { accountFetchProfileRoutine, accountGetAcknowledgementRoutine } from 'state/account/account.routines';

import PrescriptionList from 'components/prescriptions-list/PrescriptionList';
import PrescriptionCard, {
    prescriptionPayloadToProps
} from 'components/prescriptions-list/PrescriptionCard/PrescriptionCard';
import MedicineCabinetLayout from 'components/layouts/medicine-cabinet/medicine-cabinet.layout';
import DashboardCtaBlock from 'components/dashboard-cta-block/DashboardCtaBlock';
import BirdiModalContent, { OverflowModal } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import PageSection from 'ui-kit/page-section/page-section';
import { accountProfileSelector } from 'state/account/account.selectors';

// analytics
import { TrackCheckoutStep } from 'util/google_optimize/optimize_helper';

// DEMO: Import fake prescription content and slider.
import { prescriptionData } from 'util/prescriptionData';
import Slider from 'components/demo-slider/demo-slider.component';
import Button from 'ui-kit/button/button';
import '../../../styles/demo.style.scss';

const MedicineCabinet = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const cartItemsObject = useSelector(cartItemsSelector);
    // const prescriptions = useSelector(medicineCabinetPrescriptionsSelector);
    const newPrescriptionModal = useSelector(medicineShowNewPrescriptionModalSelector);
    const { resetForm, setPrescriptionFlowType } = useAddTransferPrescription();
    const profileObject = useSelector(accountProfileSelector);
    const [isAddingToCart, setIsAddingToCart] = useState(true);

    // DEMO: Import fake prescription content.
    const prescriptions = prescriptionData;

    useEffect(() => {
        const newPrescriptionsModalContent = (prescriptions: string[]) => {
            const isMultiple = prescriptions.length > 1;
            const prescriptionsList = isMultiple ? prescriptions.join(', ') : prescriptions;
            return (
                <BirdiModalContent
                    icon={'default'}
                    title={
                        isMultiple
                            ? t('modals.newPrescriptions.title.multiplePrescriptions')
                            : t('modals.newPrescriptions.title.singlePrescription')
                    }
                    body={
                        isMultiple
                            ? t('modals.newPrescriptions.body.multiplePrescriptions', {
                                  prescriptions: `<strong>${prescriptionsList}</strong>`
                              })
                            : t('modals.newPrescriptions.body.singlePrescription', {
                                  prescription: `<strong>${prescriptionsList}</strong>`
                              })
                    }
                />
            );
        };
        if (newPrescriptionModal.show) {
            dispatch(
                openModal({
                    showClose: true,
                    className: 'prescription-modal',
                    bodyContent: newPrescriptionsModalContent(newPrescriptionModal.prescriptions),
                    onClose: () => {
                        dispatch(closeNewPrescriptionModal());
                    },
                    ctas: [
                        {
                            label: t('modals.newPrescriptions.labels.viewMedicineCabinet'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                                dispatch(closeNewPrescriptionModal());
                                navigate('/secure/medicine-cabinet');
                            }
                        }
                    ]
                })
            );
        }
    }, [newPrescriptionModal, dispatch, t]);

    useEffect(() => {
        dispatch(medicineCabinetGetAllPrescriptions({ showNewRxModal: true }));
        setIsAddingToCart(false);
    }, []);

    useEffect(() => {
        if (profileObject === undefined) {
            dispatch(accountFetchProfileRoutine.trigger());
        } else {
            dispatch(
                accountGetAcknowledgementRoutine.trigger({
                    patientId: profileObject?.epostPatientNum
                })
            );
        }
    }, [profileObject?.epostPatientNum]);

    const imageData = useStaticQuery(graphql`
        query {
            successModalPillImage: file(relativePath: { eq: "assets/images/hero-pill-image-sample.png" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
        }
    `);

    const handleAddToCartClick = (rxNumber: string) => {
        setIsAddingToCart(true);
        const currentPrescription = prescriptions.find((obj: any) => {
            return obj.rxNumber === rxNumber;
        });
        let cartRoutineSwitch;
        let rxInCart = false;
        if (!cartItemsObject || cartItemsObject?.length === 0) {
            cartRoutineSwitch = startCartRoutine;
        } else {
            cartRoutineSwitch = updateRefillLinesRoutine;
            // Make sure item isn't already in cart, due to super-fast clicking. TODO: move to saga
            //cast alreadyInCart = orderObject.refillRxs.find(action.payload.rxNumber)
            if (cartItemsObject) {
                rxInCart = cartItemsObject.some((cartItem) => cartItem.rxNumber === rxNumber);
            }
        }

        if (!rxInCart) {
            dispatch(
                cartRoutineSwitch.trigger({
                    rxNumber,
                    onFailure: (data: any) => {
                        setIsAddingToCart(false);
                        dispatch(medicineCabinetGetAllPrescriptions({ showNewRxModal: false }));
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: (
                                    <BirdiModalContent
                                        icon={'alert'}
                                        title={t(`pages.medicineCabinet.messages.callbacks.error`)}
                                        body={t(`pages.medicineCabinet.messages.callbacks.errorMessage`)}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t(`pages.medicineCabinet.messages.labels.gotIt`),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        }
                                    }
                                ]
                            })
                        );
                    },
                    onSuccess: (data: any) => {
                        const itemCount = data.refillRxs.length;
                        TrackCheckoutStep({
                            stepName: 'add',
                            step: '1',
                            cart: data,
                            prescriptions: prescriptions,
                            t: t,
                            shippingCost: 0
                        });
                        dispatch(medicineCabinetGetAllPrescriptions({ showNewRxModal: false }));
                        const refillRxItem = data.refillRxs?.find((obj: any) => {
                            return obj.rxNumber === rxNumber;
                        });
                        dispatch(
                            openModal({
                                showClose: true,
                                className: 'prescription-modal',
                                contentClassName: 'overflow-modal',
                                bodyContent: (
                                    <OverflowModal
                                        title={t('modals.updateCart.addedToCart')}
                                        text={t('modals.updateCart.addedContentsMessage', {
                                            drug: currentPrescription?.dispensedProductName,
                                            price: !refillRxItem.messageStatus
                                                ? ''
                                                : `(${formatPrice(refillRxItem.patientCopay)})`,
                                            itemCount: `<strong>${itemCount}</strong>`,
                                            count: itemCount
                                        })}
                                        warningText={
                                            !refillRxItem.messageStatus ? t('pages.cart.rxItemErrorMessage') : ''
                                        }
                                        image={imageData.successModalPillImage}
                                        internalCtas={[
                                            {
                                                label: t('modals.updateCart.labels.addMore'),
                                                dataGALocation: 'AddToCartModal',
                                                variant: 'primary',
                                                onClick: () => {
                                                    dispatch(closeModal({}));
                                                }
                                            },
                                            {
                                                label: t('modals.updateCart.labels.viewCart'),
                                                dataGALocation: 'AddToCartModal',
                                                variant: 'primary',
                                                onClick: () => {
                                                    dispatch(closeModal({}));
                                                    navigate('/secure/cart');
                                                }
                                            }
                                        ]}
                                    />
                                ),
                                ctas: []
                            })
                        );
                        setIsAddingToCart(false);
                    }
                })
            );
        } else {
            setIsAddingToCart(false);
        }
    };

    return (
        <MedicineCabinetLayout>
            <PageSection>
                <Row>
                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                        <Row xs={1} md={3} lg={3} xl={3} className="dashboard-cta-container">
                            <Col className="mb-md-0 mb-4">
                                <DashboardCtaBlock
                                    icon={'dr'}
                                    title={t('components.dashboardCtaBlock.titles.showPre')}
                                    bodyText={t('components.dashboardCtaBlock.body.showPre')}
                                    ctas={[
                                        {
                                            label: t('components.dashboardCtaBlock.ctas.showPre'),
                                            variant: 'outline-smoke',
                                            dataGALocation: 'MedicineCabinet',
                                            onClick: () => {
                                                navigate('/for-prescribers');
                                            }
                                        }
                                    ]}
                                />
                            </Col>
                            <Col className="mb-md-0 mb-4">
                                <DashboardCtaBlock
                                    icon={'pill-bottle'}
                                    title={t('components.dashboardCtaBlock.titles.reqNew')}
                                    bodyText={t('components.dashboardCtaBlock.body.reqNew')}
                                    ctas={[
                                        {
                                            label: t('components.dashboardCtaBlock.ctas.reqNew'),
                                            variant: 'outline-smoke',
                                            dataGALocation: 'MedicineCabinet',
                                            onClick: () => {
                                                resetForm();
                                                setPrescriptionFlowType({ flowType: 'New' });
                                                navigate('/secure/prescription');
                                            }
                                        }
                                    ]}
                                />
                            </Col>
                            <Col className="mb-md-0 mb-4">
                                <DashboardCtaBlock
                                    icon={'plane'}
                                    title={t('components.dashboardCtaBlock.titles.transferPre')}
                                    bodyText={t('components.dashboardCtaBlock.body.transferPre')}
                                    ctas={[
                                        {
                                            label: t('components.dashboardCtaBlock.ctas.transferPre'),
                                            variant: 'outline-smoke',
                                            dataGALocation: 'MedicineCabinet',
                                            onClick: () => {
                                                resetForm();
                                                setPrescriptionFlowType({ flowType: 'Transfer' });
                                                navigate('/secure/prescription');
                                            }
                                        }
                                    ]}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </PageSection>
            <PageSection>
                <Row>
                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                        <PrescriptionList
                            title={t('components.prescriptionList.headerTitle')}
                            ctaText={t('components.prescriptionList.ctaText')}
                            ctaDisabled={cartItemsObject?.length === 0}
                            ctaOnClick={() => navigate('/secure/cart')}
                            emptyPrescriptionListProps={{
                                bodyLineOne: t('components.prescriptionList.emptyPrescriptionList.bodyLineOne'),
                                bodyLineTwo: t('components.prescriptionList.emptyPrescriptionList.bodyLineTwo'),
                                linkCtas: [
                                    {
                                        type: 'button',
                                        label: t('components.prescriptionList.emptyPrescriptionList.reqPre'),
                                        onClick: () => {
                                            resetForm();
                                            setPrescriptionFlowType({ flowType: 'New' });
                                            navigate('/secure/prescription');
                                        },
                                        dataGAFormName: 'EmptyMedicineCabinet'
                                    },
                                    {
                                        type: 'button',
                                        label: t('components.prescriptionList.emptyPrescriptionList.transferPre'),
                                        onClick: () => {
                                            resetForm();
                                            setPrescriptionFlowType({ flowType: 'Transfer' });
                                            navigate('/secure/prescription');
                                        },
                                        dataGAFormName: 'EmptyMedicineCabinet'
                                    }
                                ]
                            }}
                        >
                            {prescriptions?.map((prescription) => (
                                <PrescriptionCard
                                    key={`medicine-cabinet-rx-card-${prescription.rxNumber}`}
                                    isAddingToCart={isAddingToCart}
                                    {...prescriptionPayloadToProps(prescription, t, () => {
                                        handleAddToCartClick(prescription.rxNumber);
                                    })}
                                />
                            ))}
                        </PrescriptionList>
                    </Col>
                </Row>
            </PageSection>

            <PageSection>
                <Row>
                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                        <h2 className="recs__title with-line">Over-the-Counter Recommendations</h2>
                        <p className="recs__text">We’d recommend the following over-the-counter products.</p>
                        <div className="recs__slider">
                            <Slider />
                            <Button
                                className="recs__cta"
                                label="Shop Over-the-Counter"
                                variant="primary"
                                type="button"
                                onClick={() => {
                                    navigate('/secure/over-the-counter');
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </PageSection>
        </MedicineCabinetLayout>
    );
};

export default MedicineCabinet;
