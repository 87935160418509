import React, { useEffect, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { concat } from 'lodash';
import { Col, Row } from 'react-bootstrap';
import Button from 'ui-kit/button/button';
import EmptyPrescriptionList, { EmptyPrescriptionListProps } from './EmptyPrescriptionList/EmptyPrescriptionList';
import './PrescriptionList.style.scss';

export interface PrescriptionListProps {
    title: string;
    ctaText?: string;
    ctaDisabled?: boolean;
    ctaOnClick?: () => void;
    children?: React.ReactNode | React.ReactNode[];
    emptyPrescriptionListProps: EmptyPrescriptionListProps;
}

const LIMIT = 9;

function FormatPrescriptionListCards({ children }: { children: React.ReactNode | React.ReactNode[] }) {
    if (children === undefined || children === null) {
        return null;
    }
    if (!Array.isArray(children)) {
        return (
            <Row>
                <Col xs={4}>{children}</Col>
            </Row>
        );
    } else {
        return (
            <Row xs={1} md={3} lg={3} xl={3} className="d-flex">
                {children.map((_card, index: number) => (
                    <Col className="mb-4 flex-fill" key={`rx-${index}`}>
                        {_card}
                    </Col>
                ))}
            </Row>
        );
    }
}

export default function PrescriptionList({
    ctaText,
    ctaDisabled,
    ctaOnClick,
    title,
    children,
    emptyPrescriptionListProps
}: PrescriptionListProps) {
    const { t } = useTranslation();
    const [showMorePrescriptions, setShowMorePrescriptions] = useState(true);
    const [prescriptionsList, setPrescriptionsList] = useState(children?.slice(0, LIMIT));
    const [prescriptionListIndex, setPrescriptionListIndex] = useState(LIMIT);

    useEffect(() => {
        children?.sort((a: any, b: any) => {
            return a.props.sortOrder - b.props.sortOrder;
        });
        if (prescriptionListIndex > LIMIT) {
            setPrescriptionListIndex(prescriptionListIndex);
            setShowMorePrescriptions(children?.length > prescriptionListIndex);
            setPrescriptionsList(children?.slice(0, prescriptionListIndex));
        } else {
            setPrescriptionsList(children?.slice(0, LIMIT));
            setShowMorePrescriptions(children?.length > LIMIT);
            setPrescriptionListIndex(LIMIT);
        }
    }, [children]);

    const handleLoadMore = () => {
        const newPrescriptionListIndex = prescriptionListIndex + LIMIT;
        const newShowMorePrescriptions = newPrescriptionListIndex < children?.length;
        const newPrescriptionsList = concat(
            prescriptionsList,
            children?.slice(prescriptionListIndex, newPrescriptionListIndex)
        );
        setPrescriptionListIndex(newPrescriptionListIndex);
        setPrescriptionsList(newPrescriptionsList);
        setShowMorePrescriptions(newShowMorePrescriptions);
    };
    return (
        <div className="prescription-list">
            <Row className="prescription-list__header">
                <Col>
                    <h2>{title}</h2>
                    <div className="spacer" />
                </Col>
                {ctaText && (
                    <Col xs={5} className="d-flex justify-content-end align-items-start">
                        <Button
                            className="prescription-list-button"
                            label={ctaText}
                            onClick={ctaOnClick}
                            type="button"
                            dataGALocation="MedicineCabinet"
                            disabled={ctaDisabled}
                        />
                    </Col>
                )}
            </Row>
            {children === undefined || (Array.isArray(children) && children.length <= 0) ? (
                <EmptyPrescriptionList {...emptyPrescriptionListProps} />
            ) : (
                <>
                    <FormatPrescriptionListCards>{prescriptionsList}</FormatPrescriptionListCards>
                    {showMorePrescriptions && (
                        <Col className="d-flex justify-content-center mt-4">
                            <Button
                                label={t('pages.medicineCabinet.loadMore')}
                                className="md-full md-pad-y-2"
                                type="button"
                                variant="primary"
                                dataGALocation="MedicineCabinet"
                                onClick={handleLoadMore}
                            />
                        </Col>
                    )}
                </>
            )}
        </div>
    );
}
