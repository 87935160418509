import React, { useState } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import Button from 'ui-kit/button/button';
import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';
import './DashboardCtaBlock.style.scss';

export interface DashboardCtaBlockButton {
    label: string;
    variant: 'primary' | 'outline-smoke' | 'secondary' | 'ghost' | undefined;
    onClick: () => void;
    dataGALocation?: string;
}

const pillBottle = require('../../assets/icons/pillBottle.svg');
const dr = require('../../assets/icons/dr.svg');
const plane = require('../../assets/icons/plane.svg');
const mapIconSrc = (icon: DashboardCtaBlockProps['icon']) => {
    switch (icon) {
        case 'pill-bottle':
            return pillBottle;
        case 'dr':
            return dr;
        case 'plane':
            return plane;
    }
};

export interface DashboardCtaBlockProps {
    icon: 'pill-bottle' | 'dr' | 'plane';
    title: string;
    bodyText: string;
    ctas?: DashboardCtaBlockButton[];
    open?: boolean;
}
export default function DashboardCtaBlock({ icon, title, bodyText, ctas = [], open = false }: DashboardCtaBlockProps) {
    const [opened, setOpened] = useState(open);
    return (
        <Container className={`dashboard-cta-block ${opened ? 'open' : 'closed'}`}>
            <Row>
                <Col className="d-flex">
                    <span className="dashboard-cta-block__icon">
                        <img src={mapIconSrc(icon)} alt={`Dashboard CTA Icon ${icon}`} />
                    </span>
                </Col>
            </Row>
            <Row className="dashboard-cta-block__content mt-md-4 px-md-2">
                <Col
                    xs={{
                        offset: 1,
                        span: 11
                    }}
                    md={{
                        offset: 0,
                        span: 12
                    }}
                >
                    <div
                        className="dashboard-cta-block__header d-flex justify-content-between align-items-center"
                        data-ga-location="Medicine Cabinet CTA Block"
                        onClick={() => {
                            setOpened(!opened);
                        }}
                        onKeyPress={() => {}}
                        role={'none'}
                    >
                        <h3 className="dashboard-cta-block__title">{title}</h3>
                        <ChevronIcon
                            direction={opened ? 'up' : 'down'}
                            style={{
                                color: 'white'
                            }}
                        />
                    </div>
                    <div className={`dashboard-cta-block__body ${opened ? 'open' : 'closed'}`}>
                        <div className="spacer" />
                        <div className="dashboard-cta-block__body-text">
                            <p>{bodyText}</p>
                        </div>
                        <div className="dashboard-cta-block__ctas">
                            {ctas.map((cta, idx) => (
                                <Button key={`dashboard-cta-block-${idx}`} {...cta} type={'button'} />
                            ))}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
